<template>
  <div class="goTop">
    <el-backtop target=".goTop">
      <i class="el-icon-caret-top"></i>
    </el-backtop>
    <Header :title="$t('message.link.creation')" />
    <img
      src="http://image.wearetogether.com.cn/creation-ukraine-n.jpg"
      width="100%"
    />
    <div class="box">
      <div class="part part1">
        <h1 class="title">{{ setting.part1.title }}</h1>
        <div class="cont">
          <div class="left">
            <p>{{ setting.part1.cont1 }}</p>
          </div>
          <div class="right">
            <p>{{ setting.part1.cont2 }}</p>
          </div>
        </div>
        <div class="picList">
          <div class="picItem">
            <img
              src="http://cdn.topartsintermational.com/creation-1.jpg"
              width="100%"
            />
          </div>
          <div class="picItem">
            <img
              src="http://cdn.topartsintermational.com/creation-2.jpg"
              width="100%"
            />
          </div>
          <div class="picItem">
            <img
              src="http://cdn.topartsintermational.com/creation-3.jpg"
              width="100%"
            />
          </div>
          <div class="picItem">
            <img
              src="http://cdn.topartsintermational.com/creation-4.jpg"
              width="100%"
            />
          </div>
          <div class="picItem">
            <img
              src="http://cdn.topartsintermational.com/creation-5.jpg"
              width="100%"
            />
          </div>
          <div class="picItem">
            <img
              src="http://cdn.topartsintermational.com/creation-6.jpg"
              width="100%"
            />
          </div>
          <div class="picItem">
            <img
              src="http://cdn.topartsintermational.com/creation-7.jpg"
              width="100%"
            />
          </div>
          <div class="picItem">
            <img
              src="http://cdn.topartsintermational.com/creation-8.jpg"
              width="100%"
            />
          </div>
        </div>
      </div>
      <div class="part part2">
        <h1 class="title">{{ setting.part2.title }}</h1>
        <div class="cont">
          <div class="left">
            <p>{{ setting.part2.cont1 }}</p>
          </div>
          <div class="right">
            <p>{{ setting.part2.cont2 }}</p>
          </div>
        </div>
        <div class="picText">
          <div class="picLeft">
            <img
              src="http://cdn.topartsintermational.com/creation-img1.png"
              width="300px"
            />
          </div>
          <div class="textRight">
            <h1 class="title">
              {{ setting.part2.title1 }}
            </h1>
            <p>{{ setting.part2.cont3 }}</p>
          </div>
        </div>
        <div class="picWall">
          <div class="picItem">
            <img
              src="http://cdn.topartsintermational.com/creation-img2.jpg"
              width="200px"
            />
          </div>
          <div class="picItem">
            <img
              src="http://cdn.topartsintermational.com/creation-img3.jpg"
              width="200px"
            />
          </div>
          <div class="picItem">
            <img
              src="http://cdn.topartsintermational.com/creation-img4.jpg"
              width="200px"
            />
          </div>
          <div class="picItem">
            <img
              src="http://cdn.topartsintermational.com/creation-img5.jpg"
              width="200px"
            />
          </div>
          <div class="picItem">
            <img
              src="http://cdn.topartsintermational.com/creation-img6.jpg"
              width="200px"
            />
          </div>
          <div class="picItem">
            <img
              src="http://cdn.topartsintermational.com/creation-img7.jpg"
              width="200px"
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
    <!-- 模态框 -->
    <el-dialog
      :visible.sync="dialogVisible"
      width="1200px"
      :show-close="false"
      custom-class="dialog"
      destroy-on-close
    >
      <video
        src="http://image.wearetogether.com.cn/creation-fir.mp4"
        controls
        width="1200px"
        style="display: block"
      ></video>
    </el-dialog>
  </div>
</template>

<script>
import Header from "../../components/header"
import Footer from "../../components/footer"
export default {
  name: "Creation",
  components: {
    Header,
    Footer,
  },
  data () {
    return {
      dialogVisible: true,
      setting: {
        part1: {
          title: this.$t('message.creation.part1.title'),
          cont1: this.$t('message.creation.part1.cont1'),
          cont2: this.$t('message.creation.part1.cont2'),
        },
        part2: {
          title: this.$t('message.creation.part2.title'),
          cont1: this.$t('message.creation.part2.cont1'),
          cont2: this.$t('message.creation.part2.cont2'),
          title1: this.$t('message.creation.part2.title1'),
          cont3: this.$t('message.creation.part2.cont3'),
        }
      },
    }
  },
};
</script>

<style scoped lang="less">
p,
h1 {
  margin: 0;
  padding: 0;
}
.goTop {
  min-width: 1240px;
  height: 100vh;
  overflow-x: hidden;
}
.box {
  width: 100%;
  background: url("http://cdn.topartsintermational.com/creation-bg.jpg")
    no-repeat;
  background-size: cover;
}
.part {
  width: 1200px;
  margin: 0 auto;
  .title {
    color: darkslategray;
    font-size: 24px;
    padding: 16px 0 0;
    text-align: center;
  }
}
.cont {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  p {
    text-indent: 1rem;
    padding: 2rem;
    color: #fff;
    font-size: 16px;
  }
  .left {
    background-color: #00294e;
    width: 48%;
  }
  .right {
    background-color: #000;
    width: 48%;
  }
}
.picList {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 16px;
  .picItem {
    width: 24%;
    &:nth-child(n + 5) {
      margin-top: 8px;
    }
  }
}
.part2 {
  padding-bottom: 16px;
  .left {
    background-color: #000;
  }
  .right {
    background-color: #660000;
  }
  .picText {
    display: flex;
    justify-content: space-between;
    background-color: #00294e;
    margin-top: 16px;
    color: #fff;
    .picLeft {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .title {
      color: #fff;
    }
    p {
      padding: 1rem;
      font-size: 16px;
      text-indent: 1rem;
    }
  }
  .picWall {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 16px;
    .picItem {
      width: 33%;
      &:nth-child(n + 4) {
        margin-top: 8px;
      }
      img {
        display: block;
        margin: 0 auto;
      }
    }
  }
}
</style>